<template>
  <div>
    <div class="container">
      <p dir="ltr" class="py-5">
      I have over three years of experience in software industry with dominated history
of working with python and django.
I do programming with interest and I use every opportunity to gain my experience and growth.I am also familiar with working as a team and I can work well with the team.
      </p>
      <br>
      <br>
      <p class="py-5">
            من سه سال هست که با پایتون کار میکنم . قبل از پایتون برای آشنایی بیشتر با برنامه نویسی #C کار می کردم.
            همچنین با زبان هایی مثل C , JavaScript و ++C و ... آشنایی دارم.
            من با علاقه برنامه نویسی می کنم و از هر فرصتی برای کسب تجربه و رشد خود استفاده می کنم.
      </p>
      <div class="row about-boxs">
        <v-col cols="12" md="6" xl="6" lg="6" >
          <div class="about-box pa-2 row">
          <v-icon large color="#0D86FF" style="font-size:5em;">mdi-pencil-ruler</v-icon>
          <div class="about-box-title mr-5 ">
            طراحی وبسایت
          </div>
          </div>
          <!-- <div class="about-box-body">1</div> -->
        </v-col>
        <v-col cols="12" md="6" xl="6" lg="6" >
          <div class="about-box pa-2 row">
          <v-icon large color="#0D86FF" style="font-size:5em;">mdi-web</v-icon>

          <div class="about-box-title mr-5 ">
            توسعه وبسایت
          </div>
          </div>
          <!-- <div class="about-box-body">1</div> -->
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',
  }
</script>
